import React from "react"
import { graphql } from "gatsby"
import {
  // Poems,
  // Layout,
  Hero,
  // Excerpts,
  // SliderQuotes,
  // DailyLatin,
} from "../components/"

const HomePage = ({ data }) => {
  // console.log(data)
  const {
    airtablePoems: { nodes: poems },
  } = data

  // const {
  //   airtableExcerpts: { nodes: excerpts },
  // } = data

  return (
    // <Layout>
    <Hero poems={poems} />
    // <DailyLatin />
    // <SliderQuotes />
    // <Poems poems={poems} title="Versek" />
    // <Excerpts excerpts={excerpts} title="Részletek" />
    // </Layout>
  )
}

export const query = graphql`
  {
    airtablePoems: allAirtable(
      filter: { table: { eq: "Poems" } }
      limit: 3
      sort: { fields: data___poems_row, order: ASC }
    ) {
      nodes {
        id
        recordId
        data {
          poems_title
          poems_excerpt {
            childMarkdownRemark {
              html
            }
          }

          poems_author
          poems_row
          poems_text {
            childMarkdownRemark {
              html
            }
          }
          poems_image {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    airtableExcerpts: allAirtable(
      filter: { table: { eq: "Excerpts" } }
      sort: { fields: data___excerpts_row, order: DESC }
      limit: 4
    ) {
      nodes {
        recordId
        id
        data {
          excerpts_title
          excerpts_excerpt
          excerpts_row
          excerpts_text {
            childMarkdownRemark {
              html
            }
          }
          excerpts_author
          excerpts_source
          excerpts_created
          excerpts_icon {
            localFiles {
              childImageSharp {
                fixed(width: 64, height: 64) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HomePage
